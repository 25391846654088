import tileBounds from './tile_bounds.json';

/**
 * Adds GeoServer source and layers to the Mapbox instance based on map extents.
 * @param {Object} mapInstance - The Mapbox map instance.
 */
export function addAduTiles(mapInstance) {
    const geoServerUrl = 'https://consciouscommunities.me/geoserver';
    const workspace = 'adu_workspace';
    const minZoomForTiles = 16;
    const loadedTiles = new Set();

    mapInstance.on('moveend', () => {
        const currentZoom = mapInstance.getZoom();

        if (currentZoom < minZoomForTiles) {
            console.log('Zoom level too low. Removing all ADU tiles.');
            loadedTiles.forEach(tileName => {
                mapInstance.removeLayer(tileName);
                mapInstance.removeSource(tileName);
                loadedTiles.delete(tileName);
            });
            return;
        }

        const mapBounds = mapInstance.getBounds();

        // Iterate over the tile bounds from the JSON
        for (const { tileName, bounds } of tileBounds) {
            const [minX, minY, maxX, maxY] = bounds;

            // Check if the current map view overlaps with the tile's bounds
            const overlaps =
                mapBounds.getWest() < maxX &&
                mapBounds.getEast() > minX &&
                mapBounds.getSouth() < maxY &&
                mapBounds.getNorth() > minY;

            if (overlaps && !loadedTiles.has(tileName)) {
                console.log(`Adding tile layer: ${tileName}`);
                mapInstance.addSource(tileName, {
                    type: 'geojson',
                    data: `${geoServerUrl}/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${workspace}:${tileName}&outputFormat=application/json`,
                    generateId: true,
                });

                mapInstance.addLayer({
                    id: tileName,
                    type: 'fill',
                    source: tileName,
                    minzoom: minZoomForTiles,
                    paint: {
                        'fill-color': '#FF5733',
                        'fill-opacity': 0.5,
                        'fill-outline-color': '#000000',
                    },
                });

                loadedTiles.add(tileName);
            } else if (!overlaps && loadedTiles.has(tileName)) {
                console.log(`Removing tile layer: ${tileName}`);
                mapInstance.removeLayer(tileName);
                mapInstance.removeSource(tileName);
                loadedTiles.delete(tileName);
            }
        }
    });
}
