import React, { useState } from 'react';
import VerticalTabs from './VerticalTabs';
import FilterResults from './FilterResults';
import PropertyDetails from './PropertyDetails';
import Comps from './Comps';
import { formatValue } from './formatUtils';

const FilterResultsSidebar = ({
  data,
  propertyData,
  sortConfig,
  handleSort,
  map,
  highlightedFeatureId,
  setHighlightedFeatureId,
  selectedProperty,
  setSelectedProperty,
  groupedData,
  groupedCompsData, // Add groupedCompsData as a prop
  csvMapping,
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const tabs = [
    { id: 'filterResults', label: 'Filter Results' },
    { id: 'propertyDetails', label: 'Property Details' },
    { id: 'comps', label: 'Comps' },
  ];

  const handleTabClick = (tabId) => {
    if (activeTab === tabId) {
      setActiveTab(null);
      setIsExpanded(false);
    } else {
      setActiveTab(tabId);
      setIsExpanded(true);
    }
  };

  return (
    <div className={`filter-results-sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <VerticalTabs tabs={tabs} activeTab={activeTab} setActiveTab={handleTabClick} />
      {isExpanded && (
        <div className="filter-results-sidebar-content">
          {activeTab === 'filterResults' && (
            <FilterResults
              data={data}
              propertyData={propertyData}
              sortConfig={sortConfig}
              handleSort={handleSort}
              map={map}
              highlightedFeatureId={highlightedFeatureId}
              setHighlightedFeatureId={setHighlightedFeatureId}
              setSelectedProperty={setSelectedProperty}
            />
          )}
          {activeTab === 'propertyDetails' && selectedProperty && (
            <PropertyDetails
              selectedProperty={selectedProperty}
              groupedData={groupedData} // Use groupedData for Property Details
              setSelectedProperty={setSelectedProperty}
              formatValue={formatValue}
            />
          )}
          {activeTab === 'comps' && selectedProperty && (
            <Comps
              selectedProperty={selectedProperty}
              groupedData={groupedCompsData} // Use groupedCompsData
              formatValue={formatValue}
            />
          )}

        </div>
      )}
      <button
        className="filter-results-toggle-button"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? '←' : '→'}
      </button>
    </div>
  );
};

export default FilterResultsSidebar;
