import React from 'react';

const Comps = ({ selectedProperty, groupedData, formatValue }) => {
  console.log('Comps - selectedProperty:', selectedProperty);
  console.log('Comps - groupedData:', groupedData);

  if (!selectedProperty) {
    return <p>No property selected for comparison data.</p>;
  }

  if (!groupedData || Object.keys(groupedData).length === 0) {
    return <p>No comparison data available for the selected property.</p>;
  }

  // Extract links from `comp_links` column in the selected property
  const links = selectedProperty.comp_links?.split(',') || [];
  console.log('Extracted Links:', links);

  // Helper function to split values while ignoring commas in numbers and add labels
  const formatWithNewLinesAndLabels = (value, index) => {
    if (typeof value === 'string') {
      const labels = [
        'Address:',
        'Distance:',
        'Sale Price:',
        'Sale PPSF:',
        'Floor Area:',
        'Bedrooms:',
        'Bathrooms:',
      ];

      // Match segments separated by commas, but allow commas within numeric values
      const segments = value.split(/,(?!\d{3})/).map((segment) => segment.trim());

      return segments.map((segment, i) => (
        <div key={`${index}-${i}`} style={{ whiteSpace: 'pre-wrap' }}>
          <strong>{labels[i] || ''} </strong>
          {segment}
        </div>
      ));
    }
    return value;
  };

  // Render grouped data dynamically
  return (
    <>
      {Object.keys(groupedData).map((category) => (
        <div key={category}>
          <h3>{category}</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <ul style={{ margin: '0', padding: '0', flex: '1', listStyle: 'none' }}>
              {groupedData[category]
                .slice(0, Math.ceil(groupedData[category].length / 2))
                .map((col, index) => {
                  const value = selectedProperty[col['Field Name']]; // Fetch value

                  // Check if the field name is one of the comps (e.g., comp_1, comp_2, etc.)
                  const isComp = col['Field Name'].match(/^comp_\d+$/);
                  const compIndex = isComp ? parseInt(col['Field Name'].split('_')[1], 10) : null;
                  const link = compIndex ? links[compIndex - 1] : null;

                  console.log(`Processing Field: ${col['Field Name']} - Value: ${value}`);

                  return (
                    <li key={col['Field Name']} style={{ marginBottom: '4px', lineHeight: '1.2' }}>
                      {isComp && link ? (
                        <a
                          href={link.trim()}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'blue', textDecoration: 'underline' }}
                        >
                          <strong>{`Comp ${compIndex}`}</strong>
                        </a>
                      ) : (
                        <strong>{col['Name as it appears on the website']}: </strong>
                      )}
                      {formatWithNewLinesAndLabels(
                        formatValue(
                          value,
                          col['Num Format'],
                          col['Prefix'],
                          col['Suffix'],
                          col['Data Type'],
                          col['Field Name'],
                          selectedProperty
                        ),
                        index
                      )}
                    </li>
                  );
                })}
            </ul>
            <ul style={{ margin: '0', padding: '0', flex: '1', listStyle: 'none' }}>
              {groupedData[category]
                .slice(Math.ceil(groupedData[category].length / 2))
                .map((col, index) => {
                  const value = selectedProperty[col['Field Name']]; // Fetch value

                  // Check if the field name is one of the comps (e.g., comp_1, comp_2, etc.)
                  const isComp = col['Field Name'].match(/^comp_\d+$/);
                  const compIndex = isComp ? parseInt(col['Field Name'].split('_')[1], 10) : null;
                  const link = compIndex ? links[compIndex - 1] : null;

                  console.log(`Processing Field: ${col['Field Name']} - Value: ${value}`);

                  return (
                    <li key={col['Field Name']} style={{ marginBottom: '4px', lineHeight: '1.2' }}>
                      {isComp && link ? (
                        <a
                          href={link.trim()}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'blue', textDecoration: 'underline' }}
                        >
                          <strong>{`Comp ${compIndex}`}</strong>
                        </a>
                      ) : (
                        <strong>{col['Name as it appears on the website']}: </strong>
                      )}
                      {formatWithNewLinesAndLabels(
                        formatValue(
                          value,
                          col['Num Format'],
                          col['Prefix'],
                          col['Suffix'],
                          col['Data Type'],
                          col['Field Name'],
                          selectedProperty
                        ),
                        index
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default Comps;
