import React, { useEffect } from 'react';
import './Layout.css'; // Consolidated CSS
import { useSidebar } from './SidebarContext';
import FilterAccordion from './FilterAccordion';

const Sidebar = ({ applyFilters, resetFilter, map, setPropertyData }) => {
  const { isCollapsed, toggleSidebar } = useSidebar();

  // Effect to handle map resizing after sidebar transitions
  useEffect(() => {
    const handleTransitionEnd = () => {
      if (map) {
        console.log("Sidebar transition ended. Resizing map."); // Log map resize
        map.resize();
      }
    };

    // Listen for the transition end event on the sidebar
    const sidebarElement = document.querySelector('.sidebar');
    sidebarElement?.addEventListener('transitionend', handleTransitionEnd);

    // Cleanup event listener on component unmount
    return () => {
      sidebarElement?.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [map, isCollapsed]);

  return (
    <div
      className={`sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`}
      style={{ height: '100vh' }}
    >
      {/* Toggle Button */}
      <div className="toggle-button-container">
        <button
          className="toggle-button"
          onClick={() => {
            console.log("Toggling sidebar."); // Log toggle action
            toggleSidebar();
          }}
        >
          {isCollapsed ? '→' : '←'}
        </button>
      </div>

      {/* Render FilterAccordion only when sidebar is expanded */}
      {!isCollapsed && (
        <FilterAccordion
          applyFilters={(filters) => {
            console.log("Applying filters.", filters); // Log filter application
            applyFilters(filters, map, setPropertyData);
          }}
          resetFilter={(key) => {
            console.log("Resetting filter for key:", key); // Log filter reset
            resetFilter(key, map);
          }}
        />
      )}
    </div>
  );
};

export default Sidebar;
