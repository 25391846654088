import { getColorRamp } from './utils/colorRamp'; // Shared utility function for color ramp

/**
 * Adds GeoServer source and layers to the Mapbox instance.
 * @param {Object} mapInstance - The Mapbox map instance.
 * @param {Function} setHighlightedFeatureId - Function to update the highlighted feature ID.
 * @param {Function} setSelectedProperty - Function to update the selected property data.
 */
export function addGeoServerLayers(mapInstance, setHighlightedFeatureId, setSelectedProperty) {
  // Add GeoServer source if it doesn't exist
  if (!mapInstance.getSource('sql_statement_11_18')) {
    mapInstance.addSource('sql_statement_11_18', {
      type: 'geojson',
      data: 'https://consciouscommunities.me/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=ne:sql_statement_11_18&outputFormat=application/json',
      generateId: true, // Ensure unique feature IDs
    });
  }

  // Add circle layer for property dots
  if (!mapInstance.getLayer('sql_statement_dots')) {
    mapInstance.addLayer({
      id: 'sql_statement_dots',
      type: 'circle',
      source: 'sql_statement_11_18',
      minzoom: 0,
      maxzoom: 24,
      paint: {
        'circle-radius': 5,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#000000',
        'circle-color': getColorRamp(), // Use shared utility for color ramp
      },
    });
  }

  // Add symbol layer for text labels
  if (!mapInstance.getLayer('sql_statement_symbols')) {
    mapInstance.addLayer({
      id: 'sql_statement_symbols',
      type: 'symbol',
      source: 'sql_statement_11_18',
      minzoom: 13,
      layout: {
        'text-field': [
          'concat',
          '$',
          ['case',
            ['>=', ['to-number', ['get', 'list_price']], 1000000], ['concat', ['slice', ['to-string', ['/', ['get', 'list_price'], 1000000]], 0, 3], 'm'],
            ['>=', ['to-number', ['get', 'list_price']], 100000], ['concat', ['slice', ['to-string', ['get', 'list_price']], 0, 3], 'k'],
            ['to-string', ['get', 'list_price']],
          ],
        ],
        'text-size': 15,
        'text-anchor': 'left',
        'text-offset': [0.75, 0],
        'text-allow-overlap': false,
      },
      paint: {
        'text-color': '#000000',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 12,
      },
    });
  }

  // Handle click event for properties
  mapInstance.on('click', 'sql_statement_dots', async (e) => {
    const clickedFeature = e.features?.[0];
    if (!clickedFeature) return;

    const featureId = clickedFeature.properties.ain; // Adjust property key as needed
    if (featureId) {
      // Highlight the clicked feature
      mapInstance.setPaintProperty(
        'sql_statement_dots',
        'circle-color',
        ['case', ['==', ['get', 'ain'], featureId], '#FF0000', getColorRamp()], // Highlight the selected feature
      );

      // Fetch additional data for the clicked feature (optional)
      try {
        const response = await fetch(`https://consciouscommunities.me/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=ne:sql_statement_11_18&CQL_FILTER=ain='${featureId}'&outputFormat=application/json`);
        if (!response.ok) throw new Error(`Failed to fetch GeoServer data: ${response.statusText}`);
        
        const featureData = await response.json();
        const selectedFeature = featureData.features?.[0]?.properties;

        // Update states with the selected feature
        setHighlightedFeatureId?.(featureId);
        setSelectedProperty?.(selectedFeature);
        console.log('Selected Feature:', selectedFeature); // Debugging
      } catch (error) {
        console.error('Error fetching feature data from GeoServer:', error);
      }
    }
  });
}
