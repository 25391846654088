// src/useMapbox.js

import { useState } from 'react';

/**
 * Custom hook to manage Mapbox-related functionality, including toggling satellite view.
 * @param {object} map - The Mapbox map instance
 * @returns {object} - Contains the toggleSatelliteView function and satelliteView state
 */
export function useMapbox() {
    const [satelliteView, setSatelliteView] = useState(false);

    /**
     * Toggles the satellite view layer on the Mapbox map.
     * @param {object} map - The Mapbox map instance
     */
    const toggleSatelliteView = (map) => {
        if (map) {
            if (satelliteView) {
                // Remove satellite layer if it exists
                if (map.getLayer('satellite')) {
                    map.removeLayer('satellite');
                    map.removeSource('satellite');
                }
            } else {
                // Add satellite layer on top of the current map style
                map.addSource('satellite', {
                    type: 'raster',
                    url: 'mapbox://mapbox.satellite',
                    tileSize: 256
                });
                map.addLayer({
                    id: 'satellite',
                    type: 'raster',
                    source: 'satellite',
                    layout: { visibility: 'visible' }
                }, 'road-label'); // Insert below labels for better visibility
            }
            setSatelliteView(!satelliteView);
        }
    };

    return { toggleSatelliteView, satelliteView };
}
