import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SidebarProvider } from './SidebarContext'; // Import SidebarProvider

ReactDOM.render(
  <SidebarProvider> {/* Wrap App with SidebarProvider here */}
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </SidebarProvider>,
  document.getElementById('root')
);
