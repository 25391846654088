import React from 'react';
import DataTable from './DataTable';

const FilterResults = ({
  data,
  propertyData,
  sortConfig,
  handleSort,
  map,
  highlightedFeatureId,
  setHighlightedFeatureId,
  setSelectedProperty,
  isLayerLoaded
}) => (
  <div className="filter-results">
    {propertyData && propertyData.length > 0 ? (
      <div className="data-table-container">

<DataTable
  data={data}
  propertyData={propertyData}
  sortConfig={sortConfig}
  handleSort={handleSort}
  highlightedFeatureId={highlightedFeatureId}
  setHighlightedFeatureId={setHighlightedFeatureId}
  map={map}
  setSelectedProperty={setSelectedProperty}
  flatDisplay={true}
/>


{console.log('Highlighted Feature ID in FilterResults:', highlightedFeatureId)} 


      </div>
    ) : (
      <p>No property data available</p>
    )}
  </div>
);

export default FilterResults;
