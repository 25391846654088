import { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { addGeoServerLayers } from './addGeoServerLayers';
import { useSidebar } from './SidebarContext';
import { addAduTiles } from './addAduTiles';

/**
 * Custom hook to initialize and manage a Mapbox instance.
 * @param {Function} setPropertyData - Function to update property data on map events.
 * @param {Function} setHighlightedFeatureId - Function to set the highlighted feature ID.
 * @param {Function} setSelectedProperty - Function to set the selected property data.
 * @param {Array} propertyData - Array of property data to use in click event.
 * @returns {Object} map - The Mapbox map instance.
 */
export function useInitializeMap(setPropertyData, setHighlightedFeatureId, setSelectedProperty, propertyData) {
  const [map, setMap] = useState(null);
  const { isCollapsed } = useSidebar(); // Access sidebar collapsed state

  const addParcelLayer = (mapInstance) => {
    const parcelDataUrl =
      'https://public.gis.lacounty.gov/public/rest/services/LACounty_Cache/LACounty_Parcel/MapServer/0/query';
    const zoomThreshold = 18;
    let parcelsLoadedBounds = [];
    let parcelsVisible = false;

    const toggleParcelsVisibility = () => {
      const currentZoom = mapInstance.getZoom();
      const visibility = currentZoom >= zoomThreshold ? 'visible' : 'none';

      if (mapInstance.getLayer('parcels-layer') && mapInstance.getLayer('parcels-outline')) {
        if (parcelsVisible && visibility === 'none') {
          mapInstance.setLayoutProperty('parcels-layer', 'visibility', 'none');
          mapInstance.setLayoutProperty('parcels-outline', 'visibility', 'none');
          parcelsVisible = false;
        } else if (!parcelsVisible && visibility === 'visible') {
          mapInstance.setLayoutProperty('parcels-layer', 'visibility', 'visible');
          mapInstance.setLayoutProperty('parcels-outline', 'visibility', 'visible');
          parcelsVisible = true;
        }
      }
    };

    const fetchParcelsInBounds = async () => {
      const bounds = mapInstance.getBounds();
      const [xmin, ymin, xmax, ymax] = [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ];

      if ([xmin, ymin, xmax, ymax].some((coord) => isNaN(coord))) return;

      for (const loadedBounds of parcelsLoadedBounds) {
        const [[loadedWest, loadedSouth], [loadedEast, loadedNorth]] = loadedBounds.toArray();
        if (xmin >= loadedWest && ymin >= loadedSouth && xmax <= loadedEast && ymax <= loadedNorth)
          return;
      }

      const currentBounds = new mapboxgl.LngLatBounds([xmin, ymin], [xmax, ymax]);
      parcelsLoadedBounds.push(currentBounds);

      const url = `${parcelDataUrl}?outFields=*&geometry={"xmin":${xmin},"ymin":${ymin},"xmax":${xmax},"ymax":${ymax},"spatialReference":{"wkid":4326}}&geometryType=esriGeometryEnvelope&inSR=4326&spatialRel=esriSpatialRelIntersects&f=geojson`;
      let allFeatures = [];
      let offset = 0;
      const limit = 1000;

      while (true) {
        const paginatedUrl = `${url}&resultOffset=${offset}&resultRecordCount=${limit}`;
        const response = await fetch(paginatedUrl);
        const data = await response.json();
        if (data.features) allFeatures = allFeatures.concat(data.features);
        if (!data.exceededTransferLimit || data.features.length < limit) break;
        offset += limit;
      }

      if (allFeatures.length > 0) {
        if (!mapInstance.getSource('parcels')) {
          mapInstance.addSource('parcels', {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: allFeatures },
          });

          mapInstance.addLayer({
            id: 'parcels-layer',
            type: 'fill',
            source: 'parcels',
            minzoom: zoomThreshold,
            paint: { 'fill-color': '#888888', 'fill-opacity': 0.1 },
            layout: { visibility: 'none' },
          });

          mapInstance.addLayer({
            id: 'parcels-outline',
            type: 'line',
            source: 'parcels',
            paint: { 'line-color': '#000000', 'line-width': 1 },
            layout: { visibility: 'none' },
          });
        } else {
          const existingData = mapInstance.getSource('parcels')._data;
          existingData.features = existingData.features.concat(allFeatures);
          mapInstance.getSource('parcels').setData(existingData);
        }

        toggleParcelsVisibility();
      }
    };

    mapInstance.on('zoomend', toggleParcelsVisibility);
    mapInstance.on('moveend', () => {
      if (mapInstance.getZoom() >= zoomThreshold) fetchParcelsInBounds();
    });
  };

  useEffect(() => {
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-118.25, 34.05],
        zoom: 10,
      });

      newMap.on('load', () => {
        addGeoServerLayers(newMap, setHighlightedFeatureId, setSelectedProperty);
        addParcelLayer(newMap);
        addAduTiles(newMap);

        if (!newMap.getSource('sql_statement_dots')) {
          newMap.addSource('sql_statement_dots', {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [] },
          });
        }

        if (!newMap.getLayer('highlighted-property')) {
          newMap.addLayer({
            id: 'highlighted-property',
            type: 'circle',
            source: 'sql_statement_dots',
            paint: {
              'circle-color': '#ff0000',
              'circle-radius': 10,
              'circle-opacity': 1,
            },
            filter: ['==', 'ain', ''],
          });
        }
      });

      newMap.on('moveend', () => {
        const visibleFeatures = newMap.queryRenderedFeatures({
          layers: ['sql_statement_dots', 'sql_statement_symbols'],
        });
        setPropertyData(
          visibleFeatures.map((feature) => ({
            ...feature.properties,
            id: feature.properties.ain || feature.properties.id, // Ensure a valid ID
          }))
        );
      });

      newMap.on('click', 'sql_statement_dots', (e) => {
        const clickedFeature = e.features[0];
        if (!clickedFeature) return;

        const featureId = clickedFeature.properties?.ain;
        if (featureId) {
          if (newMap.getLayer('highlighted-property')) {
            newMap.setFilter('highlighted-property', ['==', 'ain', featureId]);
          } else {
            console.error("Layer 'highlighted-property' not found");
          }

          setHighlightedFeatureId(featureId);
          setSelectedProperty(clickedFeature.properties);
        } else {
          console.error("Feature ID is missing in clicked feature");
        }
      });

      setMap(newMap);
    };

    if (!map) initializeMap();
  }, [map, setPropertyData, setHighlightedFeatureId, setSelectedProperty, propertyData]);

  useEffect(() => {
    if (map) {
      map.resize();
    }
  }, [map, isCollapsed]);

  return { map };
}
