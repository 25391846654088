import React from 'react';

const VerticalTabs = ({ tabs, activeTab, setActiveTab, isExpanded }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '50px',
      background: '#f0f0f0',
      borderLeft: '1px solid #ddd'
    }}
  >
    {tabs.map((tab) => (
      <button
        key={tab.id}
        style={{
          padding: '10px',
          border: 'none',
          background: activeTab === tab.id ? '#007bff' : '#e0e0e0',
          color: activeTab === tab.id ? 'white' : 'black',
          cursor: 'pointer',
          width: '100%',
          textAlign: 'center'
        }}
        onClick={() => setActiveTab(tab.id)}
      >
        {isExpanded ? tab.label : tab.label[0]} {/* Show full label if expanded */}
      </button>
    ))}
  </div>
);

export default VerticalTabs;
