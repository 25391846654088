import numeral from 'numeral';

/**
 * Formats a value based on the given format, data type, and additional properties.
 */
export const formatValue = (value, format = '0,0', prefix = '', suffix = '', dataType, fieldName, row) => {
  if (value == null || value === '') return 'N/A';

  if (fieldName === 'situsaddre') {
    const zillowLink = row?.['search_link'];
    return zillowLink ? (
      <a href={zillowLink} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    ) : (
      value
    );
  }

  if (dataType === 'Double' || dataType === 'Integer') {
    const formattedValue = numeral(value).format(format);
    return `${prefix}${formattedValue}${suffix}`;
  }

  if (dataType === 'Boolean') return value ? 'Yes' : 'No';

  return value;
};

/**
 * Filters data based on a CSV mapping object, which determines which tab a column belongs to.
 * @param {Object} data - The raw data fetched from the GeoServer.
 * @param {Object} csvMapping - A mapping of column names to their respective tabs.
 * @returns {Object} Filtered data for the respective tabs.
 */
export const parseCsvForTabs = (data, csvMapping) => {
  if (!data || !csvMapping) {
    console.warn('parseCsvForTabs not called: Missing data or csvMapping');
    return { propertyDetails: {}, comps: {} }; // Return empty objects to prevent crashes
  }

  console.log('parseCsvForTabs - Function Invoked');
  const propertyDetails = {};
  const comps = {};

  // Debugging logs for inputs
  console.log('parseCsvForTabs - Input Data:', data);
  console.log('parseCsvForTabs - CSV Mapping:', csvMapping);

  Object.keys(data).forEach((columnName) => {
    const Tab = csvMapping[columnName];
    
    if (!Tab) {
      console.warn(`Column "${columnName}" is not mapped to any tab in csvMapping`);
      return; // Skip unmapped columns
    }
  
    console.log(`Processing column: ${columnName}, Tab: ${Tab}, Value: ${data[columnName]}`);
  
    if (Tab === 'Property Details') {
      propertyDetails[columnName] = data[columnName];
    } else if (Tab === 'Comps') {
      comps[columnName] = data[columnName];
    }
  });
  

  // Debugging logs for filtered outputs
  console.log('parseCsvForTabs - Property Details:', propertyDetails);
  console.log('parseCsvForTabs - Comps:', comps);

  return { propertyDetails, comps };
};
