import { useEffect, useState } from 'react';
import Papa from 'papaparse';

/**
 * Custom hook for fetching and grouping property and comps data.
 * Parses CSV data, groups it by category, and provides data states.
 */
export function usePropertyData() {
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [compsData, setCompsData] = useState([]);
  const [groupedCompsData, setGroupedCompsData] = useState({});
  const [csvMapping, setCsvMapping] = useState({}); // State for the csvMapping

  // Fetch and parse CSV data
  useEffect(() => {
    console.log('Fetching CSV data...');
    Papa.parse('ColumnsNames-ColumnsNames.csv', {
      download: true,
      header: true,
      complete: (result) => {
        const allData = result.data;
        console.log('Parsed CSV Data:', allData);
        setData(allData);

        // Generate csvMapping
        const mapping = allData.reduce((acc, item) => {
          if (item['Field Name'] && item.Tab) {
            acc[item['Field Name']] = item.Tab; // Map field names to their respective tabs
          } else {
            console.warn(`Invalid CSV Row: ${JSON.stringify(item)}`);
          }
          return acc;
        }, {});
        console.log('Generated csvMapping:', mapping);
        setCsvMapping(mapping);
        

        // Filter for Property Details
        const propertyDetailsData = allData.filter((item) => item.Tab === 'Property Details');
        groupData(propertyDetailsData, setGroupedData);

        // Filter for Comps
        const compsTabData = allData.filter((item) => item.Tab === 'Comps');
        setCompsData(compsTabData);
        groupData(compsTabData, setGroupedCompsData);
      },
      error: (error) => {
        console.error('Error parsing CSV:', error);
      },
    });
  }, []);

  // Generic function to group data by category
  const groupData = (data, setGroupedFn) => {
    const grouped = data.reduce((acc, item) => {
      const category = item.Category || 'Uncategorized'; // Fallback to 'Uncategorized'
      if (item['Field Name'] !== 'search_lin') { // Exclude specific column
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(item);
      }
      return acc;
    }, {});
    setGroupedFn(grouped);
  };

  return { data, groupedData, compsData, groupedCompsData, csvMapping: csvMapping || {} };

}
