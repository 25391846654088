import React from 'react';

const PropertyDetails = ({ selectedProperty, groupedData, formatValue }) => {
  // Debugging: Log the current selected property
  console.log('selectedProperty:', selectedProperty);

  // Display placeholder text if no property is selected
  if (!selectedProperty) {
    return (
      <div>
        <p>To view property details, select it on the map.</p>
      </div>
    );
  }

  // Render the property details when a property is selected
  return (
    <>
      {Object.keys(groupedData).map((category) => (
        <div key={category}>
          <h3>{category}</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <ul style={{ margin: '0', padding: '0', flex: '1', listStyle: 'none' }}>
              {groupedData[category].slice(0, Math.ceil(groupedData[category].length / 2)).map((col) => (
                <li key={col['Field Name']} style={{ marginBottom: '4px', lineHeight: '1.2' }}>
                  <strong>{col['Name as it appears on the website']}: </strong>
                  {formatValue(
                    selectedProperty[col['Field Name']],
                    col['Num Format'],
                    col['Prefix'],
                    col['Suffix'],
                    col['Data Type'],
                    col['Field Name'],
                    selectedProperty
                  )}
                </li>
              ))}
            </ul>
            <ul style={{ margin: '0', padding: '0', flex: '1', listStyle: 'none' }}>
              {groupedData[category].slice(Math.ceil(groupedData[category].length / 2)).map((col) => (
                <li key={col['Field Name']} style={{ marginBottom: '4px', lineHeight: '1.2' }}>
                  <strong>{col['Name as it appears on the website']}: </strong>
                  {formatValue(
                    selectedProperty[col['Field Name']],
                    col['Num Format'],
                    col['Prefix'],
                    col['Suffix'],
                    col['Data Type'],
                    col['Field Name'],
                    selectedProperty
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default PropertyDetails;
