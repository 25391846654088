// 01.00 - Import statements
import React from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';  // Ensure CSS is imported

// 02.00 - RangeSlider Component
const RangeSlider = ({ filter, handleSliderChange, sliderKey }) => {
  return (
    <div className="filter-slider">
      <Slider
        range
        min={filter.min}
        max={filter.max}
        step={filter.sliderIncrement || 1}  // Ensure correct increment
        value={[filter.selectedMin, filter.selectedMax]}
        onChange={(newValue) => handleSliderChange(sliderKey, newValue)}  // Handle slider changes
      />
      <div className="slider-values">
        <span>
          {filter.prefix} {filter.selectedMin} {filter.suffix}
        </span>
        -
        <span>
          {filter.prefix} {filter.selectedMax} {filter.suffix}
        </span>
      </div>
    </div>
  );
};

// 03.00 - Export RangeSlider Component
export default RangeSlider;
