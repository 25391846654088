// 01.00 - Import statements and external dependencies
import React, { useState } from 'react';
import numeral from 'numeral';
import { formatValue } from './formatUtils';

// 02.00 - DataTable Component Definition
const DataTable = ({
  data = [],
  propertyData = [],
  sortConfig,
  handleSort,
  highlightedFeatureId,
  setHighlightedFeatureId,
  map,
  setSelectedProperty,
  flatDisplay = false // Default to false for backward compatibility
}) => {
  // 02.01 - Log to confirm data received for debugging purposes
  console.log("Rendering DataTable with propertyData:", propertyData);

  // 02.02 - Group data conditionally based on flatDisplay prop
  const groupedData = flatDisplay
    ? { Flat: propertyData } // Use propertyData for flat mode
    : data.reduce((acc, item) => {
        if (item['Field Name'] !== 'search_lin') {
          if (!acc[item.Category]) {
            acc[item.Category] = [];
          }
          acc[item.Category].push(item);
        }
        return acc;
      }, {});

  // 03.00 - Define state variables for pagination and category collapse
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50; // Number of rows per page
  const [collapsedCategories, setCollapsedCategories] = useState(Object.keys(groupedData));

  // 03.01 - Sort property data based on sortConfig
  const sortedPropertyData = [...propertyData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  // 03.02 - Toggle collapse for a category in the table
  const toggleCategoryCollapse = (category) => {
    setCollapsedCategories((prevState) =>
      prevState.includes(category)
        ? prevState.filter((c) => c !== category)
        : [...prevState, category]
    );
  };

  // 03.03 - Get sort indicator for the column header
  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  // 04.00 - Pagination logic to calculate current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedPropertyData.slice(indexOfFirstRow, indexOfLastRow);

  // 04.01 - Handle pagination
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // 06.00 - Render DataTable UI with grouped columns and rows
  return (
    <div style={{ overflowX: 'scroll', overflowY: 'hidden', width: '100%', display: 'block' }}>
      {/* 06.01 - Table rendering */}
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
      <thead>
  {/* Render column headers */}
  {flatDisplay && currentRows.length > 0 && (
    <tr>
      {data
        .filter((col) => col['Filter Results'] === 'TRUE')
        .map((col) => (
          <th
            key={col['Field Name']}
            style={{ border: '1px solid black', padding: '8px', cursor: 'pointer' }}
            onClick={() => handleSort(col['Field Name'])}
          >
            {col['Name as it appears on the website']} {getSortIndicator(col['Field Name'])}
          </th>
        ))}
    </tr>
  )}
</thead>



        {/* 06.04 - Render table body with property rows */}
        <tbody>
  {currentRows.length > 0 ? (
    currentRows.map((property, rowIndex) => (
      <tr
        key={rowIndex}
        style={{
          backgroundColor: String(property.id) === String(highlightedFeatureId) ? '#ffdd57' : 'white',
          transition: 'background-color 0.3s ease',
        }}
        onClick={() => {
          setHighlightedFeatureId(property.id);
          setSelectedProperty(property);

          // Ensure the Map layer updates for the highlighted property
          if (map && map.isStyleLoaded() && map.getLayer('highlighted-property')) {
            map.setFilter('highlighted-property', ['==', 'ain', property.id]);
          }
        }}
      >
        {data
          .filter((col) => col['Filter Results'] === 'TRUE')
          .map((col) => {
            const key = col['Field Name'];
            return (
              <td key={key} style={{ border: '1px solid black', padding: '8px' }}>
                {formatValue(
                  property[key],
                  col['Num Format'],
                  col['Prefix'],
                  col['Suffix'],
                  col['Data Type'],
                  key,
                  property
                )}
              </td>
            );
          })}
      </tr>
    ))
  ) : (
    <tr>
      <td
        colSpan={data.filter((col) => col['Filter Results'] === 'TRUE').length}
        style={{ textAlign: 'center', padding: '8px' }}
      >
        No data available
      </td>
    </tr>
  )}
</tbody>


      </table>

      {/* 06.05 - Pagination Controls */}
      <div style={{ padding: '10px', textAlign: 'center', width: '100%' }}>
        {[...Array(Math.ceil(sortedPropertyData.length / rowsPerPage))].map((_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            style={{ margin: '0 5px', padding: '5px 10px' }}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

// 07.00 - Export DataTable Component
export default DataTable;
